import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../Utilities/axios";
import toast from "react-hot-toast";
 
const Forgot = () => {
  const history = useHistory()
  const [loader, setLoader] = useState(false);

  const subSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")});


  const formikForogt = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: subSchema,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const formData = new FormData();
        formData.append("email", values?.email);
        const response = await axiosInstance.post("/forgetpassword", formData);
        console.log(response,'res-------------->>>>')
        if (response?.data?.status) {
            setLoader(false);
            toast.success(response?.data?.message,{duration:3000})
            history.push('/reset')
        }
      } catch (e) {
        setLoader(false);
        console.log(e);
        toast.error(e?.errors);
      }
    },
  });


    return (
      <div className="container">
        <form className="Auth-form py-4" onSubmit={formikForogt.handleSubmit}>
          <div className="Auth-form">
            <h3 className="Auth-form-title">Forgot Password</h3>
          
            <div className="form-group mt-3 text-start">
              <label htmlFor="email">Email address</label>
              <input
                className="form-control mt-1"
                placeholder="Email Address"
                type="email"
                name="email"
                onChange={formikForogt.handleChange}
                onBlur={formikForogt.handleBlur}
                value={formikForogt.values.email}
              />
              {formikForogt.touched.email &&
              formikForogt.errors.email ? (
                <div
                  className="error text-start"
                  style={{ color: "red", fontSize: "12px", paddingLeft: "4px" }}
                >
                  {formikForogt.errors.email}
                </div>
              ) : null}
            </div>
            <div className="text-center py-4" style={{cursor:'pointer'}}>
              
              <span className="link-primary" onClick={()=>{history.push('/Login')}}>
                Sign In
              </span>
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="form-submit-button" disabled={loader}>{loader ? 'Loading...' :'Submit'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );



};

export default Forgot;
