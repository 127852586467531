// // import React, { useState } from 'react';
// // import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// //
// // const Example = (props) => {
// //   const [dropdownOpen, setDropdownOpen] = useState(false);
// //
// //   const toggle = () => setDropdownOpen(prevState => !prevState);
// //
// //   return (
// //     <Dropdown isOpen={dropdownOpen} toggle={toggle}>
// //       <DropdownToggle caret>
// //         Dropdown
// //       </DropdownToggle>
// //       <DropdownMenu>
// //         <DropdownItem header>Header</DropdownItem>
// //         <DropdownItem>Some Action</DropdownItem>
// //         <DropdownItem text>Dropdown Item Text</DropdownItem>
// //         <DropdownItem disabled>Action (disabled)</DropdownItem>
// //         <DropdownItem divider />
// //         <DropdownItem>Foo Action</DropdownItem>
// //         <DropdownItem>Bar Action</DropdownItem>
// //         <DropdownItem>Quo Action</DropdownItem>
// //       </DropdownMenu>
// //     </Dropdown>
// //   );
// // }
// //
// // export default Example;
//
// import React, { useState } from 'react';
// import {
//     Menu,
//     MenuItem,
//     MenuButton,
//     SubMenu,
//     FocusableItem
// } from '@szhsin/react-menu';
// import '@szhsin/react-menu/dist/index.css';
// import '@szhsin/react-menu/dist/transitions/slide.css';
// import Navigation from './Navigation';
//
// export default function Example() {
//   const [filter, setFilter] = useState('');
//     return (
//       <div className="menu">
//       <Menu menuButton={<MenuButton>Open menu</MenuButton>}
//           onMenuChange={e => e.open && setFilter('')}>
//           <FocusableItem>
//               {({ ref }) => (
//                   <input ref={ref} type="text" placeholder="Type to filter"
//                       value={filter} onChange={e => setFilter(e.target.value)} />
//               )}
//           </FocusableItem>
//           {
//               // ['Apple', 'Banana', 'Blueberry', 'Cherry', 'Strawberry']
//               //     .filter(fruit => fruit.toUpperCase()
//               //         .includes(filter.trim().toUpperCase()))
//               //     .map(fruit => <MenuItem key={fruit}>{fruit}</MenuItem>)
//               <MenuItem key={Navigation}>{Navigation}</MenuItem>
//           }
//       </Menu>
//       </div>
//     );
// }

import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
const Card = (props) => {
  console.log
  return (
    // <Link to={'/' +props.link_title} onClick={props.link_function}>
    <Link to={'/' +props.link_title} onClick={props.link_function}>
    <article
      className="card"
      style={{ width: props.carWidth ? props.carWidth : "300px" }}
    >
      <div
        className="card-image"
        style={{ height: props.imageHeight ? props.imageHeight : "300px" }}
      >
        <img alt="" src={`${props.imageUrl}`} />
      </div>

      <div className="card-content">
        <h2>{props.title}</h2>
        <p>{props.body}</p>
      </div>
    </article>
    </Link>
  );
};

Card.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  carWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
};

export default Card;
