import React, { useState, useEffect }from 'react';
import { useHistory } from "react-router-dom";
import {Component} from 'react';
import './content_page.css';
import $ from "jquery";
import ProgressBar from 'react-bootstrap/ProgressBar';
import projectsData from '../components/project.json';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Footer1 from './Footer1';
const proj = projectsData.projects;

export default function Signup_site() {

  const userData = JSON.parse(localStorage.getItem("userData"));

  const history = useHistory();
  const routeChange = (element) =>{
    history.push(element);
  }

return (
 
//   <section className="color-section" id="title">
//     <div className="container-fluid">
//     <section className="black-section" id="pricing-black">

//     <h2 className="section-heading">NMLS Mortgage Licence Prep</h2>
//     <img className="image" src={require('../components/images/APE_LOGO.jpg') }/>
//     <p>Introducing the ultimate education destination for aspiring Loan Officers. As the newest powerhouse in adult education, our program is equipped with the most advanced learning strategies and the fastest training methodologies available. Our courses are meticulously designed to ensure you not only learn faster but also retain information longer, setting you up to pass your NMLS exams on the first attempt. Join the vanguard of professionals who are choosing our top-tier, flexible online MLO Prep. Step into a future where you lead as a top-tier Loan Officer.</p>
//     { !userData &&     <button type="button" className="button is-link is-medium mt-4" onClick={(nextClickHandler) =>routeChange("/subscription")} >Sign Up $295</button>}
//     </section>
//     </div>

//     <div className="container-fluid">
//      <section className="white-section" >

//     <h2 className="section-heading">NMLS Masterclass Prep Course</h2>
//     <p>We give you everything you need upfront—no need for multiple programs or later upgrades. Get all you need now to pass your NMLS exam. As a current realtor and loan officer who has faced the same challenges you're encountering now, I know firsthand the gaps in available training materials. That's why I established Apex Prime Education LLC—to create the ultimate one-stop shop for passing your exam swiftly and starting your career as soon as possible. We don’t just prepare you to pass—we ensure you truly understand and retain what you learn. Our program, developed from thousands of hours of dedicated effort over four years, integrates proven learning strategies tailored to different learning styles and paces. Don’t settle for less. Choose Apex Prime Education LLC and accelerate your path to a successful career. </p>
//     {/* <break></break> */}
//     <p className="list">
//     <em><b>Complete Courses at Your Own Pace:</b> Progress through the curriculum on your schedule, adapting your learning to fit your life.</em></p>
//     <p className="list"><em><b>Study When and Where You Want:</b> Access our courses anywhere, anytime—perfect for on-the-go or at-home learning.</em></p>
//     <p className="list"><em><b>Comprehensive Video Lessons:</b> Engage with dynamic video content that makes complex concepts easy to understand and remember.</em></p>
//     <p className="list"><em><b>In-depth Study Guides:</b> Utilize our detailed guides to deepen your knowledge and enhance your study sessions.</em></p>
//     <p className="list"><em><b>Interactive Audio Materials:</b> Learn through audio formats ideal for commuting or whenever you prefer listening over reading.</em></p>
//     <p className="list"><em><b>Helpful Flash Cards:</b> Quickly review key terms and concepts with easy-to-use flash cards.</em></p>
//     <p className="list"><em><b>Extensive Vocabulary Lists:</b> Master the critical terminology needed to succeed in your NMLS exams.</em></p>
//     <p className="list"><em><b>Printable Learning Modules:</b> Dive deeper into complex topics with print-outs that help you better understand and retain information more efficiently.</em></p>
//     <p className="list"><em><b>Assessment Tools (Quizzes, Exams, Tests):</b> Reinforce your learning through targeted quizzes, practice tests, and comprehensive exams to guide your study through each area.</em></p>
//     <p className="list"><em><b>Customizable Study Guide Builder:</b> After your exams, easily navigate to specific areas you need to review using our innovative study guide builder—no more wasting time searching through extensive material for the information you missed.</em></p>
// { !userData &&     <button type="button" className="button is-link is-medium mt-4" onClick={(nextClickHandler) =>routeChange("/subscription")} >Sign Up $295</button>}


//     <div className="spacing">
//     </div>



//   </section>
//     </div>
// </section>
<>
<section className="color-section" id="title">
<div className="container-fluid">
<section class="black-section" id="pricing-black">

<h2 className="section-heading">NMLS Mortgage Licence Prep</h2>
<img className="image" src={require('../components/images/APE_LOGO.jpg') }/>
{/* <img className="image" src={require('/Users/michaelcontreras/Desktop/Web_Development/Main_web/src/components/images/APE_LOGO.jpg') }/> */}
<p>Introducing the ultimate education destination for aspiring Loan Officers. As the newest powerhouse in adult education, our program is equipped with the most advanced learning strategies and the fastest training methodologies available. Our courses are meticulously designed to ensure you not only learn faster but also retain information longer, setting you up to pass your NMLS exams on the first attempt. Join the vanguard of professionals who are choosing our top-tier, flexible online MLO Prep. Step into a future where you lead as a top-tier Loan Officer.</p>
{!userData &&
<button type="button" className="button is-link is-medium mt-4" onClick={(nextClickHandler) =>routeChange("/subscription")} >Sign Up $295</button>
}
</section>
</div>

<div className="container-fluid">
<section className="white-section" >

<h2 className="section-heading">NMLS Masterclass Prep Course</h2>
<p>We give you everything you need upfront—no need for multiple programs or later upgrades. Get all you need now to pass your NMLS exam. As a current realtor and loan officer who has faced the same challenges you're encountering now, I know firsthand the gaps in available training materials. That's why I established Apex Prime Education LLC—to create the ultimate one-stop shop for passing your exam swiftly and starting your career as soon as possible. We don’t just prepare you to pass—we ensure you truly understand and retain what you learn. Our program, developed from thousands of hours of dedicated effort over four years, integrates proven learning strategies tailored to different learning styles and paces. Don’t settle for less. Choose Apex Prime Education LLC and accelerate your path to a successful career. </p>
<break></break>
<p class="list">
<em><b>Complete Courses at Your Own Pace:</b> Progress through the curriculum on your schedule, adapting your learning to fit your life.</em></p>
<p class="list"><em><b>Study When and Where You Want:</b> Access our courses anywhere, anytime—perfect for on-the-go or at-home learning.</em></p>
<p class="list"><em><b>Comprehensive Video Lessons:</b> Engage with dynamic video content that makes complex concepts easy to understand and remember.</em></p>
<p class="list"><em><b>In-depth Study Guides:</b> Utilize our detailed guides to deepen your knowledge and enhance your study sessions.</em></p>
<p class="list"><em><b>Interactive Audio Materials:</b> Learn through audio formats ideal for commuting or whenever you prefer listening over reading.</em></p>
<p class="list"><em><b>Helpful Flash Cards:</b> Quickly review key terms and concepts with easy-to-use flash cards.</em></p>
<p class="list"><em><b>Extensive Vocabulary Lists:</b> Master the critical terminology needed to succeed in your NMLS exams.</em></p>
<p class="list"><em><b>Printable Learning Modules:</b> Dive deeper into complex topics with print-outs that help you better understand and retain information more efficiently.</em></p>
<p class="list"><em><b>Assessment Tools (Quizzes, Exams, Tests):</b> Reinforce your learning through targeted quizzes, practice tests, and comprehensive exams to guide your study through each area.</em></p>
<p class="list"><em><b>Customizable Study Guide Builder:</b> After your exams, easily navigate to specific areas you need to review using our innovative study guide builder—no more wasting time searching through extensive material for the information you missed.</em></p>

{!userData &&
<button type="button" className="button is-link is-medium mt-4" onClick={(nextClickHandler) =>routeChange("/subscription")} >Sign Up $295</button>
}

<div className="spacing">
</div>



</section>
</div>
</section>
<Footer1/>
</>
)}
