import React from "react";

const Privacy = () => {
  return (
    <>
      <h1 className="section-heading py-5">Privacy Policy</h1>
      <div className="container">
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Privacy Policy for Apex Prime Education Inc.
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          At Apex Prime Education Inc., we are committed to protecting the
          privacy and security of our clients' information. This Privacy Policy
          explains how we collect, use, disclose, and safeguard your information
          when you visit our website and use our services.
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Information Collection
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          We collect information that you provide to us when using our services.
          This may include personal information such as your name, email
          address, mailing address, phone number, and any other information
          required for the provision of our services.{" "}
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Use of Information
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          The information we collect is used for the following purposes:
        </p>
        <ul>
          <li className="text-start">- To provide and improve our services.</li>
          <li className="text-start">
            - To communicate with you regarding your service requests and
            inquiries.
          </li>
          <li className="text-start">
            - To comply with legal requirements and enforce our terms and
            conditions.
          </li>
        </ul>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Information Sharing and Disclosure
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          Apex Prime Education Inc. will not sell, rent, or lease your personal
          information to third parties.{" "}
        </p>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          We may disclose your information:
        </p>

        <ul>
          <li className="text-start">
            - To comply with legal requirements such as a law, regulation,
            warrant, subpoena, or court order.
          </li>
          <li className="text-start">
            - In connection with a merger, acquisition, or sale of all or a
            portion of our assets.
          </li>
        </ul>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
         Data Security
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          We implement a variety of security measures to maintain the safety of your personal information when you submit a request or access your personal information.
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Changes to This Privacy Policy
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
         We reserve the right to change this privacy policy at any time. Any changes will be posted on this page, and we encourage you to review our privacy policy regularly to stay informed about how we are protecting the information we collect.
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
         Governing Law
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
This Privacy Policy is governed by the laws of the State of California and any disputes relating to this policy will be subject to the jurisdiction of the courts located in Sacramento, CA.        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
         Contact Us
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
If you have any questions or concerns about this Privacy Policy, please contact us at Apexprimeeducation@gmail.com.        </p>
      </div>
    </>
  );
};

export default Privacy;
