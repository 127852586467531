import React, { useState } from 'react';
import question from '../components/question.json';

const Flashcard = ({flashcard}) => {
    const [showAnswer, setShowAnswer] = useState(false);
    const toggleAnswer = () => {
      setShowAnswer(!showAnswer);
    };

    return (
        <div className={`card  ${showAnswer ? 'show-answer' : ''}`} style={{ "width": "100%", "height":"400px" }} onClick={toggleAnswer} key={flashcard.id}>
            <div className="front" key={flashcard.id}>
                {flashcard.question}

            </div>
            {showAnswer && <div className='has-background-success has-text-white p-2'>{flashcard.answer}</div>}
        </div>

    )
}

export default Flashcard;
