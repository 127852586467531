// import React, { useState, useEffect } from 'react';
//
// const DataFetchingComponent = (repo) => {
//   console.log('repo 2');
//   console.log(repo);
//   const data = repo.repo;
//   console.log(data);
//
//
//   return (
//     <div>
//       <h1>Data from Backend</h1>
//       <div>
//          {data.map((data, index) => {
//            return (
//              <div key={index}>
//                <h2>{data.question}</h2>
//                <h2>{data.section}</h2>
//              </div>
//            );
//          })}
//        </div>
//
//     </div>
//   );
// };
//
// export default DataFetchingComponent;

import React, { useState, useEffect }from 'react';
import './styles.css';
import {useParams, Link} from "react-router-dom";
import Time_Start from './TimeStart';
import Time_Question from './TimeQuestion_TestOnly';
import Time_End from './TimeEnd';
import Time_Modal from './TimeModal';
import Time_Code from './TimeCodes';


// let interval;

const TimeQuiz = ({repo,sectionInfo,setonStep}) => {
  const [step, setStep] = useState(1);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [questionCounter, setQuestionCounter] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showModal, setShowModal] = useState(false);
    const [showCode, setShowCode] = useState(false);
  const [time, setTime] = useState(0);
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [shuffleanswers, setShuffleAnswers]=useState([]);
  const [results, setResults] = useState([]);
  const [attempted, setAttempted] = useState(null);
  const quiz_id = repo;
  console.log('quiz_id');
  console.log(quiz_id);
  console.log( repo,' repo');
  console.log(typeof(quiz_id));



  const shuffle = (array) => {
    if (!Array.isArray(array) || array?.length === 0) {
      return []; // Return an empty array or handle the invalid input appropriately
    }
  
    let currentIndex = array?.length, randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  };

  const quizStartHandler = () => {
    setStep(2);
    const setempty = (Array.from(Array(quiz_id?.length).keys()));
    const questionIndex = setempty[Math.floor(Math.random() * setempty.length)];
    setActiveQuestion(questionIndex);
    const index1= setempty.indexOf(questionIndex);
    const newVar=setempty.slice();
    newVar.splice(index1,1);
    setAvailableQuestions(newVar);
    const options = quiz_id[questionIndex].options;
    setShuffleAnswers(shuffle(options));
    // interval = setInterval(() => {
    //   setTime(prevTime => prevTime + 1);
    // }, 1000);
  }

  const resetClickHandler = () => {
    // setActiveQuestion(0);
    setQuestionCounter(0);
    setAnswers([]);
    setAvailableQuestions([]);
    setStep(2);
    setTime(0);
    setResults([]);
    setCorrectAnswers(0);
    const setempty = (Array.from(Array(quiz_id.length).keys()));
    setAvailableQuestions(setempty);
    // interval = setInterval(() => {
    //   setTime(prevTime => prevTime + 1);
    // }, 1000);
  }

  const goBack = () => {
    setStep(0);
  };

  return (
    <div className="home-box custom-box">
      {step === 1 && <Time_Start onQuizStart={quizStartHandler}
      numberOfQuestions={quiz_id?.length} />}
      {step === 2 && <Time_Question
      sectionInfo={sectionInfo}
        data={quiz_id[activeQuestion]}
        alldata ={quiz_id}
        onAnswerUpdate={setAnswers}
        numberOfQuestions={quiz_id?.length}
        activeQuestion={activeQuestion}
        onSetActiveQuestion={setActiveQuestion}
        availableQuestions ={availableQuestions}
        setAvailableQuestions={setAvailableQuestions}
        shuffleanswers={shuffleanswers}
        setShuffleAnswers={setShuffleAnswers}
        questionCounter={questionCounter}
        setQuestionCounter={setQuestionCounter}
        correctAnswers ={correctAnswers}
        setCorrectAnswers={setCorrectAnswers}
        results ={results}
        setResults ={setResults}
        onSetStep={setStep}
        attempted={attempted}
        setAttempted={setAttempted}
        repo = {repo}
      />}
      {step === 3 && <Time_End
        results={answers}
        data={quiz_id}
        numberOfQuestions={quiz_id?.length}
        onReset={resetClickHandler}
        onAnswersCheck={() => setShowModal(true)}
        onCode={() => setShowCode(true)}
        correctAnswers={correctAnswers}
        repo = {repo}
        goBack={goBack}
        setonStep={setonStep}
      />}

      {showModal && <Time_Modal
        onClose={() => setShowModal(false)}
        results={answers}
        data={quiz_id}
      />}

      {showCode && <Time_Code
        onClose={() => setShowCode(false)}
        results={answers}
        data={quiz_id}
      />}

    </div>
  );
}

export default TimeQuiz;
