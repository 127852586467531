import React, { useState, useEffect } from 'react';
import { Component } from 'react';
import './content_page.css';
import { useParams, Link } from "react-router-dom";
import Card from '../components/Card';
import subsData from '../components/subtopics.json';

export default function Videos() {
  const sub_sub = subsData.sub_section;
  const params = useParams();
  const subtopic = sub_sub.filter((sub_sub) => sub_sub.id === params.id);
  const test = subtopic[0]
  console.log('***params');
  console.log(sub_sub?.id);
  console.log('***params id');
  console.log(params.id);
  console.log('***subtopic');
  console.log(subtopic);
  console.log('***subtopic works?');
  console.log(subtopic[0]);


  const getAnimalsContent = test => {
    let content = [];

    content.push(
      <div key={subtopic[0]?.id} className="grid-table">
        {test?.combo?.map((combo, i) => (
          <Card
            key={i}
            imageUrl={require('./images/Videos.jpg') }
            title={combo?.name}
            body="Please watch this Video"
            link_title={combo?.video + '/VideoPLAY'}
          />))}

      </div>);

    return content;
  };


  return (

    <div key={subtopic[0]?.id} className="columnC">
      <div className="pricing_col">
        <h2 className="pricing_header" key={subtopic[0]?.index}>{subtopic[0]?.section + " Video Page"}</h2>
        {getAnimalsContent(test)}


      </div>
    </div>

  )
}
