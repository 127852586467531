import React from "react";

const Term = () => {
  return (
    <>
      <h1 className="section-heading py-5">Terms and Conditions</h1>
      <div className="container">
        <h6
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 500, paddingTop: "10px" }}
        >
         Welcome to Apex Prime Education Inc. We are a private entity based in Sacramento CA, specializing in assisting with education services.
        </h6>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Use of Service
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
         Our expertise at Apex Prime Education Inc. centers around providing premier educational services. This includes offering assistance with course content, designing custom learning solutions, and managing the learning process. By choosing our services, you're entrusting us with vital components of your education.
        </p>

        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
         Intellectual Property Rights{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
        All content on this website, including text, graphics, logos, and images, is the property of Apex Prime Education Inc. and is protected by copyright and intellectual property laws.
        </p>

        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
         User Conduct{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
         Users of this website are expected to use the site lawfully and are prohibited from violating any applicable laws and regulations, infringing on the rights of others, or uploading harmful content.{" "}
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          Service Descriptions and Accuracy{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
         Apex Prime Education Inc. endeavors to provide accurate and complete descriptions of our services. However, we do not warrant that all descriptions and representations are error-free.
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
        Limitation of Liability{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          Apex Prime Education Inc. will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of our services or this website.{" "}
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
         Indemnification{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          You agree to indemnify and hold harmless Apex Prime Education Inc. and its employees against any claims arising from your use of our services.
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
         Changes to Terms{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
         We reserve the right to modify these Terms and Conditions at any time. Your continued use of the website after any changes indicates your acceptance of the new terms.{" "}
        </p>
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
         Governing Law{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >These Terms and Conditions are governed by the laws of the State of California. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts located in Sacramento, CA.{" "}
        </p>

        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          What if I do not pass my test?
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
         Our course does not guarantee your passing the exam. We recommend that you achieve 85% on quizzes and exams, and 90% on your test to give you the best chance of passing. If you do not pass, email us at Apexprimeeducation@gmail.comand we will see if we can help you prepare for future exams. Don't give up.
        </p>

        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
         Contact Information{" "}
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          For questions about these Terms and Conditions, please contact us at Apexprimeeducation@gmail.com
        </p>
       
      </div>
    </>
  );
};

export default Term;
