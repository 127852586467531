import React, { useState, useEffect }from 'react';
import './styles.css';
import {useParams, Link} from "react-router-dom";
import Time_Start from './TimeStart';
import Time_Question from './TimeQuestion';
import Time_End from './TimeEnd';
import Time_Modal from './TimeModal';
import Time_Code from './TimeCodes';
// import quizData from './question_real.json';
import $ from "jquery";

// let interval;

const TimeQuiz = ({repo,sectionInfo,setonStep}) => {
  const [step, setStep] = useState(1);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [questionCounter, setQuestionCounter] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [time, setTime] = useState(0);
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [shuffleanswers, setShuffleAnswers]=useState([]);
  const [results, setResults] = useState([]);
  const [attempted, setAttempted] = useState(null);
  const params = useParams();
  // const sub_sub = quizData.quiz;
  // const quiz_id = sub_sub.filter( (sub_sub) => sub_sub.section === params.id);
  const quiz_id = repo;



//   const [test_qa, setTest] = useState(initialState);
//   const [repo, setRepo] = useState([]);
//   const getRepo = () => {
//     $.ajax({
//         type: "GET",
//         url: "http://localhost:8000/getdata.inc.php",
//         dataType: 'JSON',
//         success(data) {
//             console.log("data");
//             console.log(data);
//             console.log(typeof(data));
//             const data_qa = data.filter( (data) => data.section === params.id);
//             console.log(data_qa);
//             // setTest(data_qa);
//             // const repo = data_qa;
//             var result = Object.keys(data).map((key) => [key, data[key]]);
//             // console.log('quiz_id2');
//             // console.log(repo);
//             setRepo(data_qa);
//             console.log("repo");
//             console.log(repo);
//             //
//             // var keys = Object.keys(data);
//             // console.log(keys);
//
//             // setRepo(data.id, data.section, data.question, data.options, data.answer, data.ans_show);
//             // console.log("repo");
//             // console.log(repo);
//             // console.log(typeof(repo));
//             // const split_string = repo.split(", ");
//             // console.log(split_string);
//         },
//   })
// };
//
// // const getRepo = () => {
// //   $.ajax({
// //       type: "GET",
// //       url: "http://localhost:8000/getdata.inc.php",
// //       success: function(data) {
// //            $('.userId').each(function(key, value){
// //                       $(value).prepend(data[key].ques_id);
// //                       $(value).find('.usernm').text(data[key].question);
// //                   });
// //             console.log("data");
// //             console.log(data);
// //       },
// // })
// // };


  // useEffect(() => {
  //   if(step === 2) {
  //     getRepo();
  //   }
  // }, []);

  // useEffect(() => {
  //   if(step === 3) {
  //     clearInterval(interval);
  //   }
  // }, []);


  const shuffle = (array) => {
    if (!Array.isArray(array) || array?.length === 0) {
      return []; // Return an empty array or handle the invalid input appropriately
    }
  
    let currentIndex = array?.length, randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  };

  const quizStartHandler = () => {

    setStep(2);
    const setempty = (Array.from(Array(quiz_id?.length).keys()));
    const questionIndex = setempty[Math.floor(Math.random() * setempty.length)];
    setActiveQuestion(questionIndex);
    const index1= setempty.indexOf(questionIndex);
    const newVar=setempty.slice();
    newVar.splice(index1,1);
    setAvailableQuestions(newVar);
    const options = quiz_id[questionIndex]?.options;
    setShuffleAnswers(shuffle(options));
    // interval = setInterval(() => {
    //   setTime(prevTime => prevTime + 1);
    // }, 1000);
  }

  const resetClickHandler = () => {
    // setActiveQuestion(0);
    setQuestionCounter(0);
    setAnswers([]);
    setAvailableQuestions([]);
    setStep(2);
    setTime(0);
    setResults([]);
    setCorrectAnswers(0);
    const setempty = (Array.from(Array(quiz_id.length).keys()));
    setAvailableQuestions(setempty);
    // interval = setInterval(() => {
    //   setTime(prevTime => prevTime + 1);
    // }, 1000);
  }
  console.log('step------->>',step)

  return (
    <div className="home-box custom-box">
      {step === 1 && <Time_Start onQuizStart={quizStartHandler}
      numberOfQuestions={quiz_id?.length} />}
      {step === 2 && <Time_Question
      sectionInfo={sectionInfo}
        data={quiz_id[activeQuestion]}
        alldata ={quiz_id}
        onAnswerUpdate={setAnswers}
        numberOfQuestions={quiz_id?.length}
        activeQuestion={activeQuestion}
        onSetActiveQuestion={setActiveQuestion}
        availableQuestions ={availableQuestions}
        setAvailableQuestions={setAvailableQuestions}
        shuffleanswers={shuffleanswers}
        setShuffleAnswers={setShuffleAnswers}
        questionCounter={questionCounter}
        setQuestionCounter={setQuestionCounter}
        correctAnswers ={correctAnswers}
        setCorrectAnswers={setCorrectAnswers}
        results ={results}
        setResults ={setResults}
        onSetStep={setStep}
        attempted={attempted}
        setAttempted={setAttempted}
        repo = {repo}
      />}
      {step === 3 && <Time_End
        results={answers}
        data={quiz_id}
        numberOfQuestions={quiz_id?.length}
        onReset={resetClickHandler}
        onAnswersCheck={() => setShowModal(true)}
        onCode={() => setShowCode(true)}
        correctAnswers={correctAnswers}
        repo = {repo}
        setonStep={setonStep}
      />}

      {showModal && <Time_Modal
        onClose={() => setShowModal(false)}
        results={answers}
        data={quiz_id}
      />}

      {showCode && <Time_Code
        onClose={() => setShowCode(false)}
        results={answers}
        data={quiz_id}
      />}

    </div>
  );
}

export default TimeQuiz;
