import React, { useState, useEffect } from "react";
import { Component } from "react";
import "./content_page.css";
import { useParams, Link } from "react-router-dom";
import Card from "../components/Card";
// import projectsData from '../components/project.json';
import subsData from "../components/subtopics.json";

export default function Learning_Module() {
  const sub_sub = subsData.sub_section;
  const params = useParams();
  const subtopic = sub_sub?.filter((sub_sub) => sub_sub?.id === params?.id);
  const test = subtopic[0];
  console.log("***params");
  console.log(params);
  console.log("***params id");
  console.log(params.id);
  console.log("***subtopic");
  console.log(subtopic);
  console.log("***subtopic works?");
  console.log(subtopic[0]);

  const getAnimalsContent = (test) => {
    let content = [];

    content?.push(
      <div key={subtopic[0]?.id} className="grid-table">
        {test?.learning?.map((learn, i) => (
          //   <Card
          //   key ={i}
          // imageUrl="https://images.theabcdn.com/i/36397029/600x600/c.jpg"
          // title={learn}
          // body="Extra Learning Resources"
          // link_title = {params?.id +"/" +learn}
          // />

          <Card
            key={i}
            imageUrl={require("./images/Learning_Modules.jpg")}
            title={learn}
            body="Extra Learning Resources"
            link_title={params.id + "/" + learn}
          />
        ))}

        <Card
          imageUrl={require("./images/Learning_Modules.jpg")}
          title={"Notes"}
          body="Extra Learning Resources"
          link_title={"Notes"}
        />
      </div>
    );

    return content;
  };

  return (
    <div key={subtopic[0]?.id} className="columnC">
      <div className="pricing_col">
        <h2 className="pricing_header" key={subtopic[0]?.id}>
          {subtopic[0]?.section + " Learning Modules"}
        </h2>
        {getAnimalsContent(test)}
      </div>
    </div>
  );
}
