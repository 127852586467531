import React, { useState, useEffect, useRef }from 'react';
import ReactPlayer from 'react-player';
import {Redirect} from 'react-router-dom';
import {Component} from 'react';
import './content_page.css';
import {useParams, Link} from "react-router-dom";
// import subsData from '../components/subtopics.json';



function PlayerComponent() {
  const params = useParams();

   return(
     <video
      id="my-video"
      className="video-js"
      controls
      preload="auto"
      width="80%"
      height="90%"
      poster=""
      data-setup="{}"
    >
     <source src={"https://apexprimeeducation.com/videos/"+params.id} type="video/mp4" />
     <p className="vjs-no-js">
       To view this video please enable JavaScript, and consider upgrading to a
       web browser that
       <a href="https://videojs.com/html5-video-support/" target="_blank"
         >supports HTML5 video</a>
         </p>
       </video>
     )

};
export default PlayerComponent;
