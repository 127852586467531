import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useParams, Redirect } from 'react-router-dom';
import { Component } from 'react';
import './content_page.css';
import PdfViewer from "./pdf_viewer";
import { Link } from 'react-router-dom';



export default function Programs_Full() {
  const params = useParams();
  console.log('***params');
  console.log(params);

  return (
    <>
      <center>
        <h1>Welcome {params.id}</h1>
        <PdfViewer pdf={require("../components/images/" + params.id + ".pdf")} />
        <Link to={"../Program"} rel="noreferrer">
    Return to Program section
    </Link>

      </center>
    </>
  );
}
