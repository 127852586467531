import React, { Component , useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import { useHistory } from "react-router-dom";
import {Navigate } from "react";
import Home_Page from "./components/course_content";
import Signup_site from "./components/Signup_site";
import Law from "./components/FDL";
import GMK from "./components/GMK";
import MLO from "./components/MLO";
import Eth from "./components/Eth";
import USC from "./components/USC";
import Tests from "./components/Tests";
import Assessment from "./components/Assessment";
import Results from "./components/Results";
import Landing_Substopic from "./components/Landing_subtopic";
import Resources from "./components/Resources";
import Videos from "./components/Videos";
import Quiz from "./components/Quiz_v2";
import TimeQuiz from "./components/Random_Quiz";
import PlayerComponent from "./components/VideoPLAY";
import Programs from "./components/Programs";
import Programs_Full from "./components/Programs_FullPDF";
import Programs_Links from "./components/pdf_download";
import Auth_func from "./components/Auth";
import Subscription from "./components/Subscription";
import Forgot from "./components/forgot";
import Reset from "./components/Reset";
import Navbar from './components/Navigation';
import Learning_Module from './components/Learning_Module';
import Flashcard_main from './components/Flashcard_main';
import Notes from './components/Notes';
import { createBrowserHistory } from 'history';
import Register from './components/Register';
import Refund from './components/Refund';
import Cookie from './components/Cookie';
import Privacy from './components/Privacy';
import FAQ from './components/FAQ';
import Term from './components/Term';


// import DataFetchingComponent from './components/datafetch';

export default function Routes(){
  // const history = useHistory();
  const history = createBrowserHistory({forceRefresh:true});
  const userData = JSON.parse(localStorage.getItem("userData"));

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          userData ? (
            <Component {...props} />
          ) : (   
            <Redirect to="/Login" />
          )
        }
      />
    );
  };

return(

      //  <BrowserRouter>
      //   <div>
      //        <Navbar />
      //       <Switch>
      //       <Route exact path="/" component={Signup_site}/>
      //       <Route path="/Login" component={ Auth_func}/>
      //       <Route path="/forgotpassword" component={ Forgot}/>
      //       <Route path="/reset" component={Reset}/>
      //       <Route path="/subscription" component={Subscription}/>

      //       <PrivateRoute exact path="/Home" component={ () => <Home_Page/>}/>
      //       {/* ### automate this and pass element through law module? */}
      //       <PrivateRoute exact path="/FDL" component={Law} />

      //       <PrivateRoute exact path="/GMK" component={ GMK} />
      //       <PrivateRoute exact path="/MLO" component={MLO} />
      //       <PrivateRoute exact path="/Ethics" component={Eth} />
      //       <PrivateRoute exact path="/USC" component={USC} />
      //       <PrivateRoute exact path="/Exam" component={Tests} />
      //       <PrivateRoute exact path="/Test" component={Assessment} />
      //       <PrivateRoute exact path="/Info" component={Results} />
      //       <PrivateRoute exact path="/:id/Landing" component={Landing_Substopic} />
      //       <PrivateRoute exact path="/:id/Program" component={Programs} />
      //       <PrivateRoute exact path="/:id/Program/Full" component={Programs_Full} />
      //       <PrivateRoute exact path="/:id/Resources" component={Resources} />
      //       <PrivateRoute exact path="/:id/Videos" component={Videos}/>
      //       <PrivateRoute exact path="/:id/Quiz" component={Quiz} />
      //       {/* // <PrivateRoute exact path="/:id/Random" component={TimeQuiz} /> */}
      //       <PrivateRoute exact path="/:id/pdf_down" component={Programs_Links} />
      //       <PrivateRoute exact path="/:id/VideoPLAY" component={PlayerComponent} />
      //       <PrivateRoute exact path="/:id/Learning_Module" component={Learning_Module} />
      //       <PrivateRoute exact path="/:id/Flashcards" component={Flashcard_main} />
      //       <PrivateRoute exact path="/Notes" component={Notes} />

      //      </Switch>
      //   </div>
      // </BrowserRouter>
      <BrowserRouter>
      <div>
           <Navbar />
          <Switch>
          <Route exact path="/" component={Signup_site}/>
          <Route path="/Login" component={ Auth_func}/>
          <Route path="/register" component={Register}/>
          <Route path="/forgotpassword" component={ Forgot}/>
          <Route path="/reset" component={Reset}/>
          <Route path="/subscription" component={Subscription}/>
          <Route exact path="/Home" component={ () => <Home_Page/>}/>

          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/term&condition" component={Term} />
          <Route exact path="/refund" component={Refund} />
          <Route exact path="/cookie" component={Cookie} />
          <Route exact path="/privacy" component={Privacy} />
          <PrivateRoute exact path="/FDL" component={Law} />
          <PrivateRoute exact path="/GMK" component={ GMK} />
          <PrivateRoute exact path="/MLO" component={MLO} />
          <PrivateRoute exact path="/Ethics" component={Eth} />
          <PrivateRoute exact path="/USC" component={USC} />
          <PrivateRoute exact path="/Exam" component={Tests} />
          <PrivateRoute exact path="/Test" component={Assessment} />
          <PrivateRoute exact path="/Info" component={Results} />
          <PrivateRoute exact path="/:id/Landing" component={Landing_Substopic} />
          <PrivateRoute exact path="/:id/Program" component={Programs} />
          <PrivateRoute exact path="/:id/Program/Full" component={Programs_Full} />
          <PrivateRoute exact path="/:id/Resources" component={Resources} />
          <PrivateRoute exact path="/:id/Videos" component={Videos}/>
          <PrivateRoute exact path="/:id/Quiz" component={Quiz} />
          <PrivateRoute exact path="/:id/Random" component={TimeQuiz} />
          <PrivateRoute exact path="/:id/pdf_down" component={Programs_Links} />
          <PrivateRoute exact path="/:id/VideoPLAY" component={PlayerComponent} />
          <PrivateRoute exact path="/:id/Learning_Module" component={Learning_Module} />
          <PrivateRoute exact path="/:id/Flashcards" component={Flashcard_main} />
          <PrivateRoute exact path="/Notes" component={Notes} />

         </Switch>
      </div>
    </BrowserRouter>
    );
  }
