import React from 'react';

const Time_Modal = ({ onClose, results, data }) => {

  results.sort((a, b) => a.id - b.id);
  data.sort((a, b) => a.id - b.id);
console.log(results.sort((a, b) => a.id - b.id),'-------->>> result time modal')
console.log(data.sort((a, b) => a.id - b.id),'-------->>> data time modal')
  return(
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Your answers</p>
        </header>
        <section className="modal-card-body content">
          <ul>
            {results.sort((a, b) => a.id - b.id).map((result, i) => (
              <li key={i} className="mb-6">
                <p><strong>{result.q}</strong></p>
               <p className={result.a ===  data.sort((a, b) => a.id - b.id)?.[i].answer ? 'has-background-success has-text-white p-2' : 'has-background-danger has-text-white p-2'}>Your answer: {result.a}</p>
                {result.a !== data[i].answer && <p className="has-background-link has-text-white p-2">Correct answer: {data.sort((a, b) => a.id - b.id)?.[i].answer}</p>}
                {<p className="bg-warning has-text-white p-2">Code: {data.sort((a, b) => a.id - b.id)?.[i].code}</p>}
              </li>
            ))}
          </ul>
        </section>
        <div className="modal-footer">
        <button className="close btn btn-secondary" data-dismiss="modal" onClick={onClose}>Close</button>
         </div>
      </div>
    </div>
  );
}

export default Time_Modal;
