import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Component } from "react";
import "./content_page.css";
import $ from "jquery";
import ProgressBar from "react-bootstrap/ProgressBar";
import projectsData from "../components/project.json";

const proj = projectsData.projects;

export default function Home_Page() {
  const [selected, setSelected] = useState("");
  const [clickedItem, setClickedItem] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [code_filter, setValue] = useState("");
  const [score, setScore] = useState("");
  const userData =  JSON.parse(localStorage.getItem("userData"));
  const username = userData?.username;
  const userid = userData?.userid;
  
  const history = useHistory();
  const routeChange = (element) => {
    console.log("**** element *****");
    console.log(element);
    const id = element.id;
    console.log("**** element id *****");
    console.log(id);
    let path = element;
    history.push(path);
  };

  // const handleUser = async () => {
    if (!userData) {
      history.push("/Login");
    }
  // };

  // useEffect(() => {
  //   handleUser();
  // }, []);

  const getAnimalsContent = (proj) => {
    let content = [];
    for (let i = 0; i < proj.length; i++) {
      const item = proj[i];

      content.push(
        <div key={item.id} className="columnC">
          <div className="pricing_col">
            <h2 className="pricing_header" key={item.index}>
              {item.section}
            </h2>
            <img
              className="landing-image"
              src={require("../components/images/" + item.button + ".jpg")}
            />
          </div>

          <button
            key={item.page}
            type="button"
            className="button is-link is-medium is-fullwidth mt-4"
            onClick={(nextClickHandler) => routeChange(item.page)}
          >
            {item.button}
          </button>
        </div>
      );
      // console.log(item.options);
      // console.log(item.id);
      // console.log(item.section);
      // console.log(item.page);
      // console.log(selected);
    }
    return content;
  };

  return (
    <section className="color-section" id="title">
      <div className="container-fluid">
        <section className="white-section" id="pricing">
          <h2 className="section-heading">Welcome,</h2>

          <h3 className="section-heading">
          watch your instructional video on how to navigate this course!
          </h3>
          <video
            id="1"
            className="video-js"
            controls
            preload="auto"
            width="80%"
            height="90%"
            poster=""
            data-setup="{}"
          >
            <source
              src="https://apexprimeeducation.com/hope_test/static/media/Home_Page_Video.6b1c1fe12799eb6a0ebd.mp4"
              type="video/mp4"
            />
            <p className="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that
              <a
                href="https://videojs.com/html5-video-support/"
                target="_blank"
              >
                supports HTML5 video
              </a>
            </p>
          </video>

          {getAnimalsContent(proj)}

          <div className="spacing"></div>
        </section>
      </div>
    </section>
  );
}
