import React from 'react';
import Flashcard from './Flashcard';
import {useParams, } from "react-router-dom";
import './content_page.css';

const FlashcardList = (repo) => {
    const flashcards = repo.repo;
    console.log(flashcards);
    const params = useParams();
    return (

        <div className="grid-table">
            {flashcards.map(flashcard => {
                return <Flashcard flashcard={flashcard} key={flashcard.id} />

            })}

            <a href={"../"+params.id + "/Flashcards"} rel="noreferrer">
             Return to Flashcard Page
            </a>
        </div>

    )
}

export default FlashcardList;
